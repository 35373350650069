<template>
    <div>
        <div class="page-header">
            <lable class="page-title">Member</lable>
            <div class="page-header-actions">
                <ul>
                    <li v-on:click="isActive='list'" v-bind:class="{ active: isActive=='list' }">
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 7C0 7.81389 0.659791 8.47368 1.47368 8.47368C2.28758 8.47368 2.94737 7.81389 2.94737 7C2.94737 6.18611 2.28758 5.52632 1.47368 5.52632C0.659791 5.52632 0 6.18611 0 7ZM0 12.5263C0 13.3402 0.659791 14 1.47368 14C2.28758 14 2.94737 13.3402 2.94737 12.5263C2.94737 11.7124 2.28758 11.0526 1.47368 11.0526C0.659791 11.0526 0 11.7124 0 12.5263ZM0 1.47368C0 2.28758 0.659791 2.94737 1.47368 2.94737C2.28758 2.94737 2.94737 2.28758 2.94737 1.47368C2.94737 0.659791 2.28758 0 1.47368 0C0.659791 0 0 0.659791 0 1.47368ZM4.05263 7C4.05263 7.81389 4.71242 8.47368 5.52631 8.47368H15.8421C16.656 8.47368 17.3158 7.81389 17.3158 7C17.3158 6.18611 16.656 5.52632 15.8421 5.52632H5.52632C4.71242 5.52632 4.05263 6.18611 4.05263 7ZM4.05263 12.5263C4.05263 13.3402 4.71242 14 5.52632 14H15.8421C16.656 14 17.3158 13.3402 17.3158 12.5263C17.3158 11.7124 16.656 11.0526 15.8421 11.0526H5.52632C4.71242 11.0526 4.05263 11.7124 4.05263 12.5263ZM5.52632 0C4.71242 0 4.05263 0.659791 4.05263 1.47368C4.05263 2.28758 4.71242 2.94737 5.52631 2.94737H15.8421C16.656 2.94737 17.3158 2.28758 17.3158 1.47368C17.3158 0.659791 16.656 0 15.8421 0H5.52632Z"  />
                        </svg>
                    </li>
                    <li v-on:click="isActive='grid'" v-bind:class="{ active: isActive=='grid' }">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.8585 0H9.96029C9.33006 0 8.81738 0.805634 8.81738 1.796V5.388C8.81738 6.37836 9.33006 7.184 9.96029 7.184H14.8585C15.4887 7.184 16.0014 6.37836 16.0014 5.388V1.796C16.0014 0.805634 15.4887 0 14.8585 0Z" />
                            <path d="M6.05476 0H1.15658C0.526348 0 0.0136719 0.805634 0.0136719 1.796V5.388C0.0136719 6.37836 0.526348 7.184 1.15658 7.184H6.05476C6.68499 7.184 7.19767 6.37836 7.19767 5.388V1.796C7.19767 0.805634 6.68499 0 6.05476 0Z" />
                            <path d="M14.8448 8.81641H9.94662C9.31639 8.81641 8.80371 9.62204 8.80371 10.6124V14.2044C8.80371 15.1948 9.31639 16.0004 9.94662 16.0004H14.8448C15.475 16.0004 15.9877 15.1948 15.9877 14.2044V10.6124C15.9877 9.62204 15.475 8.81641 14.8448 8.81641Z" />
                            <path d="M6.041 8.81641H1.14289C0.512669 8.81641 0 9.62204 0 10.6124V14.2044C0 15.1948 0.512669 16.0004 1.14289 16.0004H6.041C6.67123 16.0004 7.1839 15.1948 7.1839 14.2044V10.6124C7.1839 9.62204 6.67123 8.81641 6.041 8.81641Z" />
                        </svg>
                    </li>
                </ul>
            </div>
        </div>
        <div class="table-actions">
            <div class="d-flex align-items-center">
                <a>Select All</a>
                <label>3 members selected</label>
            </div>
            <div class="table-actions-right">
                <a v-b-modal.delete ><img src="@/assets/images/delete.svg">DELETE</a>
                <a><img src="@/assets/images/download.svg">IMPORT</a>
            </div>
        </div>
        <div class="members_view list-table checkbox-table" v-bind:class="{ active: isActive=='list' }">
            <table>
                <thead>
                    <tr>
                        <th><span>NAME</span></th>
                        <th><span class="ascending">PROFILE </span></th>
                        <th><span class="descending">LOCATION</span></th>
                        <th><span class="ascending">ADMIN ROLE</span></th>
                        <th><span class="ascending">MOBILE NUMBER</span></th>
                        <th><span>TASKS</span></th>
                        <th><span>ACTION</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="td-title">
                                <b-form-checkbox></b-form-checkbox>
                                <figure>
                                    <img src="@/assets/images/user.png">
                                    <figcaption><router-link to="/member-details">Sinha</router-link></figcaption>
                                </figure>
                            </div>
                        </td>
                        <td>Center coodinator</td>
                        <td>Mumbai</td>
                        <td>Super Admin</td>
                        <td>+91 9906632981</td>
                        <td>
                            <ul class="td-tasks">
                                <li>
                                    <span>COMPLETED</span>
                                    <label>127</label>
                                </li>
                                <li>
                                    <span>PENDING</span>
                                    <label>04</label>
                                </li>
                                <li>
                                    <span>OVERDUE</span>
                                    <label>11</label>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="td-actions">
                                <li><img src="@/assets/images/message.svg"></li>
                                <li v-b-modal.edit-profile><img src="@/assets/images/edit.svg"></li>
                                <li><img src="@/assets/images/envelope.svg"></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="td-title">
                                <b-form-checkbox></b-form-checkbox>
                                <figure>
                                    <img src="@/assets/images/user.png">
                                    <figcaption><router-link to="/member-details">Sinha</router-link></figcaption>
                                </figure>
                            </div>
                        </td>
                        <td>Center coodinator</td>
                        <td>Mumbai</td>
                        <td>Super Admin</td>
                        <td>+91 9906632981</td>
                        <td>
                            <ul class="td-tasks">
                                <li>
                                    <span>COMPLETED</span>
                                    <label>127</label>
                                </li>
                                <li>
                                    <span>PENDING</span>
                                    <label>04</label>
                                </li>
                                <li>
                                    <span>OVERDUE</span>
                                    <label>11</label>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="td-actions">
                                <li><img src="@/assets/images/message.svg"></li>
                                <li v-b-modal.edit-profile><img src="@/assets/images/edit.svg"></li>
                                <li><img src="@/assets/images/envelope.svg"></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="td-title">
                                <b-form-checkbox></b-form-checkbox>
                                <figure>
                                    <img src="@/assets/images/user.png">
                                    <figcaption><router-link to="/member-details">Sinha</router-link></figcaption>
                                </figure>
                            </div>
                        </td>
                        <td>Center coodinator</td>
                        <td>Mumbai</td>
                        <td>Super Admin</td>
                        <td>+91 9906632981</td>
                        <td>
                            <ul class="td-tasks">
                                <li>
                                    <span>COMPLETED</span>
                                    <label>127</label>
                                </li>
                                <li>
                                    <span>PENDING</span>
                                    <label>04</label>
                                </li>
                                <li>
                                    <span>OVERDUE</span>
                                    <label>11</label>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="td-actions">
                                <li><img src="@/assets/images/message.svg"></li>
                                <li v-b-modal.edit-profile><img src="@/assets/images/edit.svg"></li>
                                <li><img src="@/assets/images/envelope.svg"></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="td-title">
                                <b-form-checkbox></b-form-checkbox>
                                <figure>
                                    <img src="@/assets/images/user.png">
                                    <figcaption><router-link to="/member-details">Sinha</router-link></figcaption>
                                </figure>
                            </div>
                        </td>
                        <td>Center coodinator</td>
                        <td>Mumbai</td>
                        <td>Super Admin</td>
                        <td>+91 9906632981</td>
                        <td>
                            <ul class="td-tasks">
                                <li>
                                    <span>COMPLETED</span>
                                    <label>127</label>
                                </li>
                                <li>
                                    <span>PENDING</span>
                                    <label>04</label>
                                </li>
                                <li>
                                    <span>OVERDUE</span>
                                    <label>11</label>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="td-actions">
                                <li><img src="@/assets/images/message.svg"></li>
                                <li v-b-modal.edit-profile><img src="@/assets/images/edit.svg"></li>
                                <li><img src="@/assets/images/envelope.svg"></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="td-title">
                                <b-form-checkbox></b-form-checkbox>
                                <figure>
                                    <img src="@/assets/images/user.png">
                                    <figcaption><router-link to="/member-details">Sinha</router-link></figcaption>
                                </figure>
                            </div>
                        </td>
                        <td>Center coodinator</td>
                        <td>Mumbai</td>
                        <td>Super Admin</td>
                        <td>+91 9906632981</td>
                        <td>
                            <ul class="td-tasks">
                                <li>
                                    <span>COMPLETED</span>
                                    <label>127</label>
                                </li>
                                <li>
                                    <span>PENDING</span>
                                    <label>04</label>
                                </li>
                                <li>
                                    <span>OVERDUE</span>
                                    <label>11</label>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="td-actions">
                                <li><img src="@/assets/images/message.svg"></li>
                                <li v-b-modal.edit-profile><img src="@/assets/images/edit.svg"></li>
                                <li><img src="@/assets/images/envelope.svg"></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="td-title">
                                <b-form-checkbox></b-form-checkbox>
                                <figure>
                                    <img src="@/assets/images/user.png">
                                    <figcaption><router-link to="/member-details">Sinha</router-link></figcaption>
                                </figure>
                            </div>
                        </td>
                        <td>Center coodinator</td>
                        <td>Mumbai</td>
                        <td>Super Admin</td>
                        <td>+91 9906632981</td>
                        <td>
                            <ul class="td-tasks">
                                <li>
                                    <span>COMPLETED</span>
                                    <label>127</label>
                                </li>
                                <li>
                                    <span>PENDING</span>
                                    <label>04</label>
                                </li>
                                <li>
                                    <span>OVERDUE</span>
                                    <label>11</label>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="td-actions">
                                <li><img src="@/assets/images/message.svg"></li>
                                <li v-b-modal.edit-profile><img src="@/assets/images/edit.svg"></li>
                                <li><img src="@/assets/images/envelope.svg"></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="td-title">
                                <b-form-checkbox></b-form-checkbox>
                                <figure>
                                    <img src="@/assets/images/user.png">
                                    <figcaption><router-link to="/member-details">Sinha</router-link></figcaption>
                                </figure>
                            </div>
                        </td>
                        <td>Center coodinator</td>
                        <td>Mumbai</td>
                        <td>Super Admin</td>
                        <td>+91 9906632981</td>
                        <td>
                            <ul class="td-tasks">
                                <li>
                                    <span>COMPLETED</span>
                                    <label>127</label>
                                </li>
                                <li>
                                    <span>PENDING</span>
                                    <label>04</label>
                                </li>
                                <li>
                                    <span>OVERDUE</span>
                                    <label>11</label>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="td-actions">
                                <li><img src="@/assets/images/message.svg"></li>
                                <li v-b-modal.edit-profile><img src="@/assets/images/edit.svg"></li>
                                <li><img src="@/assets/images/envelope.svg"></li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="members_view grid-section" v-bind:class="{ active: isActive=='grid' }">
            <div class="grid-cnt">
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
                <div class="card-item">
                    <figure>
                        <img src="@/assets/images/profile.png">
                    </figure>
                    <div class="card-title">
                        <label>Rahul Raj</label>
                        <p>Center coodinator</p>
                    </div>
                    <div class="card-details">
                        <label>TASK</label>
                        <ul>
                            <li>
                                <p>Completed</p>
                                <span>127</span>
                            </li>
                            <li>
                                <p>Pending</p>
                                <span>04</span>
                            </li>
                            <li>
                                <p>over due</p>
                                <span>11</span>
                            </li>
                        </ul>                         
                        <div class="card-actions">
                            <a><img src="@/assets/images/message.svg"></a>
                            <a><img src="@/assets/images/edit.svg"></a>
                            <a><img src="@/assets/images/envelope.svg"></a>
                        </div>
                         <b-form-checkbox></b-form-checkbox>
                    </div>
                </div>
            </div>
        </div>
            
        <b-modal ref="my-modal" id="delete" centered hide-header hide-footer>
            <span class="close"  @click="hideModal"><span aria-hidden="true">&times;</span></span>
            <div class="alert-messages">
                <label>Delete 3 member ?</label>
                <div class="modal-foot">
                    <button @click="hideModal">CANCEL</button>
                    <button @click="hideModal">YES</button>
                </div>
            </div>
        </b-modal>
        <b-modal ref="my-modal" id="edit-profile" size="lg" centered hide-header hide-footer>
            <span class="close" @click="hideModal"><span aria-hidden="true">&times;</span></span>
            <div class="edit-modal"> 
                <div class="edit-pofile">
                    <div class="profile_dp">
                        <figure><img src="@/assets/images/profile.png"></figure>
                        <span><img src="@/assets/images/upload_icon.png"></span>    
                    </div>
                    <div class="profile-details">
                        <h6>Profile Details</h6>
                        <ul>
                            <li>
                                <label>First Name</label>
                                <input class="form-control" type="text" value="Raj">
                            </li>
                            <li>
                                <label>Second Name</label>
                                <input class="form-control" type="text" value="Sinha">
                            </li>
                            <li>
                                <label>Profile Type</label>
                                <multiselect
                                    v-model="Profileselected"
                                    :show-labels="false" 
                                    :options="Profileoptions">
                                </multiselect>
                            </li>
                            <li>
                                <label>Admin Role Type</label>
                                <multiselect
                                    v-model="role"
                                    :show-labels="false" 
                                    :options="roleoptions">
                                </multiselect>
                            </li>
                            <li>
                                <label>Email ID</label>
                                <input class="form-control" type="email" value="raj.sinha@heartfulness.com">
                            </li>
                            <li>
                                <label>Mobile Number</label>
                                <input class="form-control" type="text" value="+91 8829300925">
                            </li>
                            <li>
                                <label>Location</label>
                                <input class="form-control" type="text" value="Bangalore">
                            </li> 
                        </ul>
                    </div>
                </div>
                <div class="modal-foot">
                    <button @click="hideModal">CANCEL</button>
                    <button @click="hideModal">YES</button>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import Multiselect from "vue-multiselect-inv";
  export default {
    components: {
        Multiselect
    },
    data() {
      return {
        isActive: 'list',
        Profileselected:['CEO'],
        Profileoptions: ['CEO', 'Center Coordinator', 'Volunter Manager', 'Corporate Team', 'School Team', 'Center Coordinator', 'Volunter Manager', 'Corporate Team', 'School Team'],
        roleoptions: ['Super Admin', 'Admin'],
        role:['Super Admin']
      }
    },
    methods: {
      showModal() {
        this.$refs['my-modal'].show() 
      },
      hideModal() {
        this.$refs['my-modal'].hide() 
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn') 
      },
     




      
    },
  }
</script>